import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getDashboard, logout } from "../services/api";

function Dashboard() {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const response = await getDashboard();
        setMessage(response.data.message);
      } catch (error) {
        console.error("Dashboard error:", error);
      }
    };
    fetchDashboard();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  return (
    <div>
      <h2>Dashboard</h2>
      <p>{message}</p>
      <Link to="/profile">Profile</Link>
      <Link to="/change-password">Change Password</Link>
      <Link to="/delete-account">Delete Account</Link>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Dashboard;
