import React, { useState } from "react";
import { Link } from "react-router-dom";
import { changePassword } from "../services/api";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await changePassword(currentPassword, newPassword);
      setMessage("Password changed successfully");
    } catch (error) {
      console.error("Change password error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Current Password"
          required
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          required
        />
        <button type="submit">Change Password</button>
      </form>
      {message && <p>{message}</p>}
      <Link to="/dashboard">Back to Dashboard</Link>
    </div>
  );
}

export default ChangePassword;
