import React from "react";
import { Link } from "react-router-dom";

const dummyArticles = [
  {
    id: 1,
    title: "Getting Started with Deltoid",
    excerpt:
      "Learn how to create your first app using Deltoid's AI-powered platform.",
    date: "2024-08-01",
  },
  {
    id: 2,
    title: "5 Tips for Optimizing Your Deltoid App",
    excerpt:
      "Discover how to make your Deltoid-generated app faster and more efficient.",
    date: "2024-09-15",
  },
  {
    id: 3,
    title: "The Future of AI in App Development",
    excerpt:
      "Explore how AI is revolutionizing the way we build and deploy applications.",
    date: "2024-06-01",
  },
  {
    id: 4,
    title: "Case Study: How Company X Scaled with Deltoid",
    excerpt:
      "Read about how Company X used Deltoid to rapidly prototype and launch their successful startup.",
    date: "2024-06-15",
  },
];

function Blog() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Deltoid Blog
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn about AI-powered app development and stay up to date with
            Deltoid.
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {dummyArticles.map((article) => (
            <article
              key={article.id}
              className="flex max-w-xl flex-col items-start justify-between"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={article.date} className="text-gray-500">
                  {article.date}
                </time>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <Link to={`/blog/${article.id}`}>
                    <span className="absolute inset-0"></span>
                    {article.title}
                  </Link>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                  {article.excerpt}
                </p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
