import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Add this import
import {
  login as apiLogin,
  logout as apiLogout,
  getCurrentUser,
} from "../services/api";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Add this line

  useEffect(() => {
    // Check if the user is already logged in when the app loads
    const checkLoggedIn = async () => {
      try {
        const currentUser = await getCurrentUser();
        console.log("eeeee");
        console.log(currentUser);
        setUser(currentUser);
      } catch (error) {
        console.error("Error checking logged in status:", error);
      } finally {
        setLoading(false);
      }
    };

    checkLoggedIn();
  }, []);

  const login = async (email, password) => {
    try {
      const userData = await apiLogin(email, password);
      setUser(userData);
      return userData;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await apiLogout();
      setUser(null);
      navigate("/"); // Redirect to home page after logout
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const value = {
    user,
    login,
    logout,
    loading,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
