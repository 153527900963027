import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { deleteAccount } from "../services/api";

function DeleteAccount() {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await deleteAccount(password);
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Delete account error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <h2>Delete Account</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Confirm Password"
          required
        />
        <button type="submit">Delete Account</button>
      </form>
      {message && <p>{message}</p>}
      <Link to="/dashboard">Back to Dashboard</Link>
    </div>
  );
}

export default DeleteAccount;
