import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000/api";
console.log("API_URL:", API_URL);

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getCurrentUser = async () => {
  try {
    const response = await api.get("/auth/current-user");
    return response.data;
  } catch (error) {
    console.error("Error fetching current user:", error);
    throw error;
  }
};

export const login = async (email, password) => {
  const response = await api.post("/auth/login", {
    email: email.toLowerCase(),
    password,
  });
  return response;
};

export const signup = async (formData) => {
  console.log("Attempting signup with formData");
  console.log(formData);
  try {
    const response = await api.post("/auth/signup", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Signup response:", response);
    return response;
  } catch (error) {
    console.error(
      "Signup error:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const forgotPassword = (email) =>
  api.post("/auth/recover", { email: email.toLowerCase() });

export const resetPassword = (token, newPassword) =>
  api.post("/auth/reset-password", { token, newPassword });
export const logout = () => api.post("/auth/logout");
export const getDashboard = () => api.get("/dashboard");
export const getProfile = () => api.get("/profile");
export const updateProfile = (email, firstName, lastName, bio) =>
  api.put("/profile", { email: email.toLowerCase(), firstName, lastName, bio });
export const changePassword = (currentPassword, newPassword) =>
  api.post("/change-password", { currentPassword, newPassword });
export const deleteAccount = (password) =>
  api.post("/delete-account", { password });

export const generateApp = (prompt, email) =>
  api.post("/app/generate", { prompt, email });

export const signupWithGoogle = async (token) => {
  const response = await api.post("/auth/google", { token });
  return response.data;
};

export const joinWaitlist = async (email) => {
  try {
    const response = await api.post("/join-waitlist", { email });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.error || "Failed to join the waitlist"
    );
  }
};

export const sendContactMessage = async (name, email, message) => {
  try {
    const response = await api.post("/contact", { name, email, message });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.error || "Failed to send contact message"
    );
  }
};
